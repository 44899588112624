<template>
    <fragment>
        <div class="flex space-between margin-btm-24">
            <h2>Error History</h2>
            <button class="custom-btn" :disabled="disableDownloadButton">
                <img src="@/assets/images/icons/download.png" @click="downloadErrorHistory"/>
            </button>
        </div>
        <data-table :entries="errorHistories" :headers="errorHistoryHeader" :loading="loader">
            <template #date="{ item }"> {{ formatDate(item.created_on) }} </template>
        </data-table>
    </fragment>
</template>
  
<script>
import { Fragment } from "vue-fragment";
import DataTable from "@/components/table/DataTable";
import TableHeader from "@/components/table/TableHeader";
import { DateTime } from "luxon";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import { downloadFile } from "../../../../filters";

export default {
    name: "PartnerErrorHistory",
    components: {
        Fragment,
        DataTable,
    },
    data() {
        return {
            loader: true,
            errorHistories: [],
            disableDownloadButton: true
        };
    },
    computed: {
        partnerID() {
            return this.$route.params.id;
        },
        errorHistoryHeader() {
            return [
                new TableHeader({ label: "Error Date", headerClass: "cell25 text-left", name: "date", cellContents: "created_on" }),
                new TableHeader({ label: "Error Description", headerClass: "cell75 text-left", name: "description", cellContents: "description" })
            ]
        }
    },
    methods: {
        async getErrorhistory() {
            this.loader = true;
            await restApi.post(`/partner/getErrorHistory`, encodeWithParam({ partnerID: this.partnerID }))
                .then(({ data }) => {
                    this.errorHistories = data?.result.errorHistory?.sort((item1, item2) => {
                        return new Date(item2.created_on) - new Date(item1.created_on);
                    });
                    this.disableDownloadButton = false;
                    this.loader = false;
                }).catch(() => {
                    this.loader = false;
                })
        },
        async downloadErrorHistory() {
            this.disableDownloadButton = true;
            restApi.post('partner/downloadErrorHistory', encodeWithParam({ partnerID: this.partnerID })).then((data) => {
                downloadFile(data.data, `Error History.csv`);
                this.disableDownloadButton = false;
            })
        },
        formatDate(date) {
            return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat("hh:mm a, LL/dd/yyyy");
        },
    },
    mounted() {
        this.getErrorhistory();
    }
};
</script>

<style scoped>
.custom-btn {
    padding: 9px 10px;
    border-radius: 4px;
    border: 1px solid #CCC;
    height: 40px;
    cursor: pointer;
    background-color: #FFF;
}
</style>
  