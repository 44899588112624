<template>
  <fragment>
    <main>
      <Message v-if="displayMessage" class="toastpayment" @close="closeToast"> Payment Method has been changed to <span
          class="boldname">{{messageChange}}</span> successfully</Message>

      <Message v-if="displayMessageNew" class="toastpayment" @close="closeToast"> Driver has been unregistered as business.</Message>

      <div class="flex space-between margin-btm-32">
        <h2>Personal Info</h2>
        <div>
          <!-- <h5 class="dropdown" style="margin-right: 40px">Tax Consented: {{taxConsented}}</h5>
          <div v-if="getRole == 'ACCOUNT_MANAGER'" class="dropdown"
            :class="`${taxApproved ? 'dropdown' :'dropdown-disabled'}`">
            <div v-if="taxData" style="color: red; margin-bottom: 5px">Tax Packet not available</div>
            <button class="dropbtn">Download Tax Packet <i class="right-icon icon-chevron-down"> </i></button>
            <div :class="`${taxApproved ? 'dropdown-content' :'dropdown-content-disabled'}`">
              <a v-for="year of years" :key="year" @click="downloadTaxPacket(year)">{{year}}</a>
            </div>
          </div> -->
          <button v-if="Object.keys(businessDetails).length > 0  && (isPartnerEditable() || getRole==='SUPERUSER'|| getRole === 'ONBOARDING_ACCOUNT_MANAGER')" class="button secondary btn btn-padding btn-margin red" @click="openUnregister">Unregister as business</button>
          <button v-if="Object.keys(businessDetails).length > 0 && (isPartnerEditable() || getRole==='SUPERUSER' || getRole === 'ONBOARDING_ACCOUNT_MANAGER')" class="button secondary btn btn-padding btn-margin" @click="openEditBusiness">Edit Business Details</button>
          <button v-if="Object.keys(businessDetails).length <= 0 && (isPartnerEditable() || getRole==='SUPERUSER'|| getRole === 'ONBOARDING_ACCOUNT_MANAGER') " class="button secondary btn btn-padding btn-margin" @click="openAddBusiness">Add Business Details</button>
          <button class="button secondary btn btn-padding btn-margin" v-if="isPartnerEditable() || getRole==='SUPERUSER'" @click="openEditPartner">Edit Personal Info</button>
        </div>
      </div>
      <div class="grid">
        <div class="col-4">
          <div class="module-form">
            <h4>ADDRESS</h4>
            <div class="partner-profile-box justify-content">
              <div>
                <p>{{ maskDetail("address1", partner.address1, true) }} <span
                    v-if="partner.address2">{{maskDetail("address2", partner.address2, true)}}</span></p>
                <p>{{ maskDetail("city", partner.city, true) }}<span v-if="partner.state">, {{
                    maskDetail("addressState", partner.state, true) }}</span></p>
                <p>{{ maskDetail("county", partner.county, true) }}</p>
                <p>{{ maskDetail("postalCode", partner.postalCode, true) }}</p>
                <p>{{ maskDetail("country", partner.country, true) }}</p>
              </div>
              <div class="inner-icon" :class="!this.getPermission('viewAdlPII') && 'disable-eye'"  @click="handleIsMasked('address')" v-if="!this.getPermission('viewMaskPII')">
                <img v-if="isMasked.address" src="@/assets/images/icons/eye-closed.png" />
                <img v-else-if="!isMasked.address" src="@/assets/images/icons/eye-open.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="module-form">
            <h4>DRIVERS LICENSE</h4>
            <div class="partner-profile-box justify-content">
              <div>
                <div class="inner-div">
                  <div>
                    <!-- <label>State</label>
                    <p>{{ maskDetail("dlState", driversLicenses.state, true) }}</p> -->
                    <label>Number</label>
                    <!-- <p>{{ maskDetail("dlNumber", driversLicenses.driverLicense, true) }}</p> -->
                    <p>{{ maskDetail("dlNumber",this.dlNumber, true) }}</p>
                    <label>Expiration</label>
                    <!-- <p>{{ maskDetail("dlExp", driversLicenses.expiration, true) }}</p> -->
                    <p>{{ maskDetail("dlExp", this.dlExpiry, true) }}</p>
                    <label>Province</label>
                    <p>{{ maskDetail("dl_state", this.dl_state, true) }}</p>
                  </div>
                </div>
              </div>
              <div @click="handleIsMasked('driversLicense')" v-if="!this.getPermission('viewMaskPII')" class="inner-icon" :class="!this.getPermission('viewAdlPII') && 'disable-eye'">
                <img v-if="isMasked.driversLicense" src="@/assets/images/icons/eye-closed.png" />
                <img v-else-if="!isMasked.driversLicense" src="@/assets/images/icons/eye-open.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="module-form">
            <h4>SENSITIVE</h4>
            <div class="partner-profile-box justify-content">
              <div>
                <div class="inner-div">
                  <div>
                    <label>Date of Birth</label>
                    <p v-if="getRole == 'COORDINATOR'">** ** ****</p>
                    <p v-else>{{ maskDetail("dateOfBirth", partner.dateOfBirth, true) }}</p>
                    <label>GST/HST</label>
                    <p>{{driversLicenses.is_gst_hst_enabled}}</p>
                  </div>
                  <label>Social Insurance Number</label>
                  <p v-if="getRole == 'COORDINATOR'">{{ maskDetail("ssn", sinNumber, true) }}</p>
                  <p v-else>{{ maskDetail("ssn", sinNumber, true) }}</p>
                  <!-- <p>{{sinNumber}}</p> -->
                </div>
              </div>
              <div class="inner-icon" @click="handleIsMasked('sensitive')" :class="!this.getPermission('viewAllPII') && 'disable-eye'" v-if="!this.getPermission('viewMaskPII')">
                <img v-if="isMasked.sensitive" src="@/assets/images/icons/eye-closed.png" />
                <img v-else-if="!isMasked.sensitive" src="@/assets/images/icons/eye-open.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-4">
          <div class="module-form">
            <h4>EXTERNAL IDS</h4>
            <div class="partner-profile-box">
              <div class="inner-div">
                <!-- <label>PDLOG ID</label> -->
                <!-- <p>{{ partner.pdlog }}</p> -->
                <label>Payfare ID:</label>
                <p>{{getPayfareID}}</p>
                <label>DMS ID:</label>
                <p>{{ dmsID }}</p>
                <label>Checkr ID:</label>
                <p> {{  checkrID }}</p>
                <!-- <label>DMS ID</label>
                <p><span v-if="isExternalIDAvailable"> {{ partner.dms_id }}</span>
                  <span v-else>NOT AVAILABLE <button @click="sendToDMS(partner.id)">Send to DMS</button></span>
                </p>
                <p class="error-message" v-if="dmsError">{{dmsError}}</p>
                <p class="message" v-if="dmsSuccess">{{dmsSuccess}}</p>
                <label>Checkr ID</label>
                <p>{{ partner.checkr }}</p>
                <p v-if="checkrErrorMessageComputed" class="error-message text-wrap"> {{checkrErrorMessageComputed}}</p>
                <label>Tax Classification</label>
                <p>{{ businessDetails.federalTaxClassification }}</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="module-form">
            <h4>EARNINGS</h4>
            <div class="partner-profile-box">
              <div class="inner-div flex">
              <div>
                 <label>Earnings method</label>
                 <input type="radio" class="radioButton" :checked="true"/><p>Direct Deposit (via Payfare)</p>
                <p>Active since {{ this.payfareCreated | formatDatePacific }}</p>
                <!-- <div class="inner-icon1" @click="handleIsMasked('driversLicense')" v-if="getRole != 'COORDINATOR'">
                <img v-if="isMasked.dlNumber" src="@/assets/images/icons/eye-closed.png" />
                <img v-else-if="!isMasked.dlNumber" src="@/assets/images/icons/eye-open.png" />
              </div> -->

              </div>
              </div>
            </div>
            </div>

        </div>
        <!-- <div class="col-8">
          <div class="module-form">
            <h4>EARNINGS</h4>
            <div class="partner-profile-box">
              <div class="inner-div flex" style="margin-left: 8px;">
                <div class="col-6">
                  <div class="radio-btns">



                  <div style="margin-bottom: 12px;">

                      <div class="extra-info">
                        <div >
                          <span class="active-cls">Active</span> since {{formatDatePacific(oneLastModifited ?
                            oneLastModifited : oneData[0].created_on)}}
                        </div>

                      </div>
                    </div>  -->
                     <div>
                      <div id="show-ach-modal" class="modal" aria-hidden="true">
                        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close" @click="cancleChanges">
                          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby"
                            style="min-height: 300px;">
                            <div class="modal-content">
                              <div class="module">
                                <span style="margin: 45%;"><img src="@/assets/images/logos/walmart-trash.svg" /></span>
                                <p class="pclass">Are you sure you want to unregister as a business ?</p>
                              </div>
                              <label style="padding-left: 0px;margin-left: 15%;">Once driver's business details deleted, they can't be recovered</label>
                              <div class="button-group close-top">
                                <a class="button w-button-primary yesBut" data-micromodal-close @click="acceptChanges">Yes, Unregister</a>
                                <a class="button secondary w-button-secondary noBut" style="margin-left: 20px"
                                  data-micromodal-close @click="cancleChanges">No, Cancel</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

               <!--    <div style="margin-bottom: 12px;">

                      <div class="extra-info">
                        <div v-if="checkWalletStatus('ONE')">
                          <span class="active-cls">Active</span> since {{formatDatePacific(oneLastModifited ?
                            oneLastModifited : oneData[0].created_on)}}
                        </div>

                      </div>
                    </div>
                    <div>
                      <div id="show-one-modal" class="modal" aria-hidden="true">
                        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close" @click="cancleChanges">
                          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby"
                            style="min-height: 300px;">
                            <div class="modal-content">
                              <div class="module">
                                <span><img src="@/assets/images/logos/infoblue.svg" /></span>
                                <p class="pclass">Are you sure you want to Activate/Deactivate <b>ONE</b> payment provider</p>

                              </div>
                              <label style="padding-left: 0px">Reason for switching earnings provider</label>
                                <label style="float: right;">(Mandatory)</label>
                               <div class="notes-user-message">
                            <textarea class="notes-display" name="message" id="" cols="2" rows="2" v-model="partnerRemarks"></textarea>
                            <p class="error-message" v-if="true">{{remarksMsg}}</p>
                          </div>
                              <div class="module">
                              </div>
                              <div class="button-group close-top">
                                <a class="button w-button-primary yesBut"
                                  @click="changePayment('ONE')">Yes, Confirm</a>
                                <a class="button secondary w-button-secondary noBut" style="margin-left: 20px"
                                  data-micromodal-close @click="cancleChanges">No, Cancel</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    </div>
                    <div>
                      <div id="show-branch-modal" class="modal" aria-hidden="true">
                        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close" @click="cancleChanges">
                          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby"
                            style="min-height: 300px;">
                            <div class="modal-content">
                              <div class="module">
                                <span><img src="@/assets/images/logos/infoblue.svg" /></span>
                                <p class="pclass">Are you sure you want to Activate/Deactivate <b>BRANCH</b> payment provider </p>

                              </div>
                 
                                <label style="padding-left: 0px">Reason for switching earnings provider</label>
                                <label style="float: right;">(Mandatory)</label>
                               <div class="notes-user-message">
                            <textarea class="notes-display" name="message" id="" cols="2" rows="2" v-model="partnerRemarks"></textarea>
                          </div>
                              <div class="button-group close-top">
                                <a class="button w-button-primary yesBut" data-micromodal-close
                                  @click="changePayment('BRANCH')">Yes, Confirm</a>
                                <a class="button secondary w-button-secondary noBut" style="margin-left: 20px"
                                  data-micromodal-close @click="cancleChanges">No, Cancel</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div id="show-provider-error" class="modal" aria-hidden="true">
                        <div class="modal-overlay" tabindex="-1" data-micromodal-close="data-micromodal-close">
                          <div class="modal-container" role="dialog" aria-modal="true" aria-labelledby="aria-labelledby"
                            style="min-height: 300px;">
                            <div class="modal-content" style="text-align: center">
                              <div class="module">
                                <span><img src="@/assets/images/logos/fi_alert-circle2.svg" /></span>
                              </div>
                              <div class="module">
                                <p class="p-warning pre-formatted">{{ providerErrorMessage }}</p>
                              </div>
                              <div class="button-group close-top">
                                <a class="button w-button-primary yesBut" data-micromodal-close>
                                  Ok
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
           
                    <div class="vl"></div>
                

                <div class="col-6">
                    <div v-if="paymentProcessor == 'ACH'">
                      <div v-if="achData.length">
                        <label>Account Number (Last 4 Digits)</label>
                      <p>{{ lastFourAccNO}}</p>
                      <label>ACH Status</label>
                      <p>{{ achData[0].status}}</p>
                      <label>Created On</label>
                      <p>{{formatHistory(achData[0].created_on)}}</p>
                       <label>{{isAchActive ? 'Deactivate' : 'Activate'}} ACH</label>
                      <div style="margin: 5px 0px 0px 20px;">
                        <InputSwitch v-model="isAchActive" @click="paymentSwitch('ACH')"/>
                      </div>
                      </div>
                      <div v-else>
                        <label>ACH Data Not Available</label>
                      </div>
                    </div>

                     <div v-if="paymentProcessor == 'BRANCH'">
                   <div v-if="branchData.length">
                       <div class="align-padding">
                      <label>Branch Wallet Link</label>
                      <div class="flex">
                        <input v-model="magicLink" type="text" id="magiclink" readonly aria-readonly="true"
                          :style="{ marginLeft: '20px', marginRight: '12px', width: '300px' }" class="primary-blue" />
                        <button @click="copyMagiclink"
                          class="button secondary btn btn-padding primary-blue">Copy</button>
                      </div>
                     </div>

                     <div class="align-padding">
                      <label>Branch Wallet Status</label>
                      <p>{{ branchData[0].status }}{{ reasonCode }}
                        <span :style="{ marginLeft: '10px' }" v-if="invalidWalletStatus" class="primary-blue">
                          <a v-if="!isResent" @click="resendWallet">Resend to Branch <i class="icon-send"></i></a>
                          <span v-else>Resending to Branch</span>
                        </span>
                      </p>
                      <p v-if="errorMessage != ''" class="error-message">Error: {{ errorMessage }}</p>
                      <p v-if="isResent" :class="messageClass ? 'success' : 'error'">{{ resentMessage }}</p>
                     </div>
                     <div class="align-padding">
                      <label>Resent to Branch History</label>
                      <div
                        :class="errorMessage.includes('postal code') ? 'resent-to-branch-history-postal' : 'resent-to-branch-history'">
                        <p v-for="(item, index) in walletHistory" :key="index">{{formatHistory(item.created_on)}}</p>
                      </div>
                    </div>
                    <div class="align-padding">
                      <label>{{isBranchActive ? 'Deactivate' : 'Activate'}} Branch</label>
                    <div style="margin: 5px 0px 0px 20px;">
                        <InputSwitch v-model="isBranchActive" :disabled="isInvalidStatus(branchData[0].status)" @click="paymentSwitch('BRANCH')"/>
                      </div>
                    </div>
                   </div>
                    <div v-else>
                        <label>Branch Data Not Available</label>
                      </div>
                  </div>
                  <div v-if="paymentProcessor == 'ONE'">
                    <div v-if="oneData.length">
                      <label>ONE ID</label>
                      <p class="bold">{{ oneData[0].one_user_id }}</p>
                      <label>Created On</label>
                      <p>{{ formatHistory(oneData[0].created_on) }}</p>
                      <label>ONE Status</label>
                      <p>{{oneData[0].one_status}}</p>
                     <div >
                       <label>{{isOneActive ? 'Dectivate':'Activate'}} ONE</label>
                      <div style="margin: 5px 0px 0px 20px;">
                        <InputSwitch v-model="isOneActive" :disabled="isInvalidStatus(oneData[0].one_status)" @click="paymentSwitch('ONE', $event)"/>
                      </div>
                     </div>
                    </div>
                    <div v-else>
                      <label>ONE Data Not Available</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="grid" v-if="Object.keys(businessDetails).length > 0">
        <div class="col-6">
          <div class="module-form">
            <h4>BUSINESS DETAILS</h4>
            <div class="inner-icon" style="float:right;margin: 20px;" @click="handleIsMasked('bAddress')"
              v-if=" getRole != 'LOCATION_MANAGER'">
              <img v-if="isMasked.bAddress" src="@/assets/images/icons/eye-closed.png" />
              <img v-else-if="!isMasked.bAddress" src="@/assets/images/icons/eye-open.png" />
            </div>
            <div class="partner-profile-box">
              <div class="profile-box-content">
                <label>Business name</label>
                <p>{{ maskDetail("businessName",businessDetails.businessName, true) }}</p>
                <label>Business number</label>
                <p>{{ maskDetail("businessName",businessDetails.ein, true) }}</p>
                <label>HST/GST number</label>
                <p>{{ maskDetail("businessName",businessDetails.federalTaxClassification, true) }}</p>
                <label>Business address</label>
                <p>{{ maskDetail("businessAddress1",businessDetails.address1, true) }}, <span
                    v-if="businessDetails.address2">{{ maskDetail("businessAddress2",businessDetails.address2, true
                    )}}</span></p>
                <p>{{ maskDetail("businessCity",businessDetails.city?businessDetails.city:"", true) }}, <span>{{
                    maskDetail("businessState",businessDetails.state?businessDetails.state: "", true) }}</span></p>
                <p>{{ maskDetail("businessZipcode",businessDetails.postalCode, true) }}</p>
                <p>{{ maskDetail("businessCountry",businessDetails.country, true) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </main>
    <partner-profile-edit partner-modal-name="editPartner" :partner="partner" :partner-id="partner.id"
      @refetch="refetch" />
    <partner-business-edit partner-modal-name="editPartnerBusiness" :partner="partner" :partner-id="partner.id"
      @refetch="refetch" />
      <partner-business-add partner-modal-name="addPartnerBusiness" :partner="partner" :partner-id="partner.id"
      @refetch="refetch" />
  </fragment>
</template>

<script>
  import { DateTime } from "luxon";
  import { Fragment } from "vue-fragment";
import {  decrypt } from "@/filters";
  import micromodal from "micromodal";
  import PartnerProfileEdit from "@/modules/admin/partners/profile/PartnerProfileEdit";
  import PartnerBusinessEdit from "@/modules/admin/partners/profile/PartnerBusinessEdit";
  import PartnerBusinessAdd from "@/modules/admin/partners/profile/PartnerBusinessAdd";
  import { restApi } from "@/http/http.config";
  import { encodeWithParam } from "@/util/Base64Encoding";
  import JSZip from "jszip";
  import { saveAs } from 'file-saver';
  import fetch from 'node-fetch';
  import { mapGetters } from "vuex";
  import partnerMethods from "./Helper/Index";
  import Message from 'primevue/message';
  import TableHeader from "@/components/table/TableHeader";
  // import DataTable from "@/components/table/DataTable";
  // import InputSwitch from 'primevue/inputswitch';

  // import { IconEyeClosed } from "@/components";
  const STATUS = {
    CLOSED: "CLOSED",
    FAILED: "FAILED",
    DENIED: "DENIED",
    UNCLAIMED: "UNCLAIMED",
    ACCOUNT_CLOSED: "ACCOUNT_CLOSED"
  }

  export default {
    name: "PartnerProfile",
    components: {
      PartnerProfileEdit,
      PartnerBusinessEdit,
      PartnerBusinessAdd,
      Fragment,
      Message,
      // DataTable,
      // InputSwitch
    },
    data: function () {
      return {
        achData: [],
        lastFourAccNO: '',
        oneData: [],
        branchData: [],
        remarksErr: false,
        remarksMsg:"",
        partnerRemarks:"",
        paymentProviderhistory: [],
        hasOneWallet: false,
        paymentProcessor: "ACH",
        isAchActive: false,
        isOneActive: false,
        isBranchActive: false,
        disableOnePayment: false,
        branchPayment: false,
        onePaymenthistory: [],
        oneLastModifited: '',
        branchPaymenthistory: [],
        branchLastModified: '',
        onePayment: false,
        isResent: false,
        resentMessage: "",
        messageClass: true,
        dmsError: "",
        dmsSuccess: "",
        years: [],
        // taxApproved: false,
        // taxConsented: "",
        taxData: false,
        messageChange: '',
        displayMessage: false,
        displayMessageNew: false,
        isMasked: {
          address: true,
          driversLicense: true,
          sensitive: true,
          bAddress: true
        },
        checkrErrorMessage: "",
        providerErrorMessage: "",
        providerStatus: {
          branch: "",
          one: "",
          location: ""
        },
        failStatuses: ["CLOSED", "FAILED", "DENIED"],
        sinNumber:"",
        dlNumber: "",
        dlExpiry:"",
        getPayfareID:"",
        dmsID:"",
        payfareCreated:"",
        checkrID:"",
        dl_state:"",
        isEditable: false,


      };
    },
    props: {
      partner: {
        type: Object,
        required: true,
        default: () => { }
      }
    },
    watch: {
      partner() {
        this.isEditable = (this.$props.partner.dms_id && this.getPermission("editPartnerPIISectionOne")) 
          || (!this.$props.partner.dms_id && this.getPermission("editCandidPIISectionOne"))
      }
    },
    computed: {
      ...mapGetters(["getRole", "getUserID", "getPermission"]),
      checkrErrorMessageComputed() {
        return this.checkrErrorMessage
      },

       headers() {
        return [
          new TableHeader({ label: "Changed On", name: "ChangedOn", headerClass: "cell10", sortable: true}),
          new TableHeader({ label: "Changed From", name: "ChangedFrom", headerClass: "cell10"}),
          new TableHeader({ label: "Changed To", name: "ChangedTo",headerClass: "cell10"}),
          new TableHeader({ label: "Changed By", name: "ChangedBy", headerClass: "cell10"}),
          new TableHeader({ label: "Remarks", name: "remarks", headerClass: "cell10"}),
        ]
       },

      isExternalIDAvailable() {
        if (this.$props.partner.approvalDate != null && this.$props.partner.dms_id == null) {
          return false;
        }
        return true;
      },
      driversLicenses() {
        return this.$props.partner.driverLicense?.length > 0 ? { ...this.$props.partner.driverLicense[0] } : {}
      },
      businessDetails() {
        console.log(this.$props.partner.tax_eligible, "Tax data")
        return this.$props.partner.tax_eligible ? { ...this.$props.partner.businessDetails[0] } : {}
      },
      magicLink() {
        return this.$props.partner.wallet_status != 'CLOSED' && this.$props.partner.wallet_status != 'FAILED' ? this.$props.partner.magicLink : ''
      },
      branchHistory() {
        return this.$props.partner.branchHistory
      },
      invalidWalletStatus() {
        return this.$props.partner.wallet_status?.toUpperCase() == "FAILED" || 
          (!this.$props.partner.wallet_response_status && this.$props.partner.wallet_status == null);
      },
      reasonCode() {
        if (this.$props.partner.wallet_response) {
          return this.$props.partner.wallet_response.reason_code ? ': ' + this.$props.partner.wallet_response.reason_code : ''
        } else {
          return ''
        }
      },
      errorMessage() {
        return this.$props.partner.message ? this.$props.partner.message : ""
      },
    },

    mounted() {
      let sinParam = {
        decoded: {
          partnerID: this.$route.params.id
        }
      }
      let payID = {
        decoded: {
          partnerID: this.$route.params.id
        }
      }
      let extIDParam = {
        decoded : {
          partnerID : this.$route.params.id
        }
      }
      restApi.post('/ca/getExtID',encodeWithParam(extIDParam)).then(({data}) =>{
        this.dmsID = data.result[0].externalID

        if(this.dmsID){
          this.$props.partner.dms_id = this.dmsID;
        }
      })
      restApi.post('/ca/getSinNumber', encodeWithParam(sinParam)).then(async ({ data }) => {
          this.sinNumber =await decrypt(data.result[0].ssn);
          this.dlNumber = data.result[0].driving_license;
          this.dlExpiry = data.result[0].dl_exp_date;
          this.dl_state = data.result[0].state;
          })

       restApi.post('/ca/getPayfareID', encodeWithParam(payID)).then(({ data }) => {
        this.getPayfareID = data.result[0].payfare_id;
        let payfareDate = data.result[0].created_on;
        this.payfareCreated = payfareDate

        // console.log(this.payfareCreated.toString().split("T")[0],"payfareCreated date")
          })

          restApi.post('/ca/getCheckrID', encodeWithParam(payID)).then(({ data }) => {
            this.checkrID = data.result[0].externalID
          })

      // restApi.get('employee/getTaxConfig').then(({ data }) => {
      //   this.taxApproved = JSON.parse(data.result[0].value).adminTaxes
      // })
      let param = {
        decoded: {
          partnerID: this.$route.params.id
        }
      }
      // restApi.post('employee/adminTaxConsent', encodeWithParam(param)).then(({ data }) => {
      //   if (data.data.length == 0) {
      //     this.taxConsented = "N/A"
      //   }
      //   else {
      //     this.taxConsented = data.data[0].tax_consent ? "YES" : "NO"
      //   }
      // })
      let date = new Date().getFullYear() - 1;
      let startYear = 2022;
      for (let i = date; i >= startYear; i--) {
        let yString = i.toString();
        this.years.push(yString);
      }
      // this.fetchPaymentProcessor(param);
      
      this.fetchPaymentStatus(param)
      this.getCheckrErrorMsg();
    },
    methods: {
      // formatDatePacific(date) {
      //   return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat("dd LLL yyyy")
      // },

      isPartnerEditable() {
        return (this.$props.partner.dms_id && this.getPermission("editPartnerPIISectionOne")) 
          || (!this.$props.partner.dms_id && this.getPermission("editPartnerPIISectionTwo"))
          // || (this.$props.partner.dms_id && this.getPermission("editCandidPIISectionOne"))
          || (!this.$props.partner.dms_id && this.getPermission("editCandidPIISectionTwo"));
      },
      cancleChanges(){
          console.log("heerr")
      },

      acceptChanges(){
        console.log("acceptChanges")
         
        let param = {
        decoded: {
          partnerID: this.$route.params.id,
          'tax_eligible' : false
        }
      }
        restApi.post('employee/unregisterBusiness', encodeWithParam(param)).then(async ({ data }) => {
          if(data.success){
            this.displayMessageNew = true;
            setTimeout(() => {
            location.reload();
        }, "1000")
          } 
        })
      },

      isInvalidStatus(status) {
        return status.toUpperCase() == STATUS.FAILED || status.toUpperCase() == STATUS.CLOSED || status.toUpperCase() == STATUS.DENIED || status.toUpperCase() == STATUS.UNCLAIMED || status.toUpperCase() == STATUS.ACCOUNT_CLOSED
      },

      
      // paymentSwitch(val){
      //   if(val==='ACH'){
      //     micromodal.show('show-ach-modal')
      //   }
      //   if(val === 'BRANCH'){
      //     micromodal.show('show-branch-modal')
      //   }
      //   if(val==='ONE'){
      //     micromodal.show('show-one-modal')
      //   }
      // },

      fetchPaymentStatus(param){
          restApi.post('employee/getProcessorStatus', encodeWithParam(param)).then((data)=>{
            this.achData = data.data.result.ach
            this.oneData = data.data.result.one
            this.branchData = data.data.result.branch
            this.lastFourAccNO = data.data.result.last_four_account_number
            this.walletHistory = data.data.result.walletHistory
            this.isAchActive = data.data.result.ach[0]?.is_active
            this.isOneActive = data.data.result.one[0]?.is_active
            this.isBranchActive = data.data.result.branch[0]?.is_active
          })
          restApi.post('employee/getProviderHistory', encodeWithParam(param)).then(async ({ data }) => {
          this.paymentProviderhistory = data.result       
        })
      },

      // showProvidersInfo(val){
      //   if(val === 'ONE'){
      //     this.paymentProcessor= 'ONE'
      //   }
      //   if(val === 'BRANCH'){
      //     this.paymentProcessor = 'BRANCH'
      //   }
      //   if(val === 'ACH'){
      //     this.paymentProcessor = 'ACH'
      //   }

      // },

      getCheckrErrorMsg() {
        this.checkrErrorMessage
        return restApi.post("/onboard/checkrErrorMessage", encodeWithParam({ partnerID: this.$route.params.id })).then((data) => {
          if (data?.data?.rowCount > 0) {
            this.checkrErrorMessage = data?.data?.result[0]?.response_payload?.error ? data?.data?.result[0]?.response_payload?.error : "Unknown Error"
          }

        })
      },
      // fetchPaymentProcessor(param) {
      //   restApi.post('employee/getPaymentProcessor', encodeWithParam(param)).then(async ({ data }) => {

      //     this.paymentProviderhistory = data.result.payActiveHistory
      //     this.paymentProviderhistory.map((item)=>{
      //       item.previourProvider = ""
      //       if(item.payment_service == 'ONE' || item.payment_service == 'One'){
      //             item.previourProvider = 'Branch'
      //       }
      //       if(item.payment_service == 'Branch'){
      //         item.previourProvider = 'ONE'
      //       }
      //     })
      //     this.hasOneWallet = data.result.rowCount == 0 ? false : true;
      //     this.providerStatus = (await restApi.post('employee/getProcessorStatus', encodeWithParam(param)))?.data.result;
      //     if (data.result.rowCount == 0 || !data.result.paymentProcessor.is_active) {
      //       // this.paymentProcessor = 'BRANCH'
      //       this.branchPayment = true
      //       this.branchPaymenthistory = data.result?.payActiveHistory.filter(item => item.payment_service === 'Branch')
      //       this.branchLastModified = this.branchPaymenthistory?.at(-1)?.tg_done_on_ts
      //       if (data.result.rowCount == 0) {
      //         this.disableOnePayment = true
      //       }
      //       if (data.result.is_one_integration_enabled) {
      //         this.disableOnePayment = false
      //       }
      //     } else if (data.result.paymentProcessor.is_active) {
      //       // this.paymentProcessor = 'ONE'
      //       this.onePayment = true
      //       this.onePaymenthistory = data.result.payActiveHistory.filter(item => item.payment_service === 'ONE')
      //       this.oneLastModifited = this.onePaymenthistory?.at(-1)?.tg_done_on_ts
      //       if (data?.result?.is_one_integration_enabled) {
      //         this.disableOnePayment = false
      //       }
      //     }
      //   })
      // },

      // changePayment(value) {
      //   if (value == 'ACH') {
      //        if(this.partnerRemarks === ""){
      //       this.remarksErr= true
      //       this.remarksMsg = "Please enter remarks"
      //     }

      //     else{
      //       micromodal.close('show-ach-modal')
      //     }
      //     let param = {
      //       isActive: this.isAchActive ? false : true,
      //       partnerId: this.$route.params.id,
      //       remarks: this.partnerRemarks,
      //       provider: "ACH"
      //     }
      //     restApi.post('employee/changePaymentProcessor', encodeWithParam(param)).then(() => {
      //      let param = {
      //   decoded: {
      //     partnerID: this.$route.params.id
      //   }
      // }

      //       this.fetchPaymentStatus(param)
      //       this.paymentProcessor = "ACH"
      //     })
      //   } else if (value == 'ONE') {
      //     if(this.partnerRemarks === ""){
      //       this.remarksErr= true
      //       this.remarksMsg = "Please enter remarks"
      //     }

      //     else{
      //       micromodal.close('show-one-modal')
      //     }

      //      let param = {
      //       isActive: this.isOneActive ? false : true,
      //       partnerId: this.$route.params.id,
      //       remarks: this.partnerRemarks,
      //       provider: "ONE"
      //     }
      //     restApi.post('employee/changePaymentProcessor', encodeWithParam(param)).then(() => {
      //        let param = {
      //   decoded: {
      //     partnerID: this.$route.params.id
      //   }
      // }

      //       this.fetchPaymentStatus(param)


      //       this.paymentProcessor = "ONE"
      //     })

      //   }
      //   else if (value == 'BRANCH') {

      //        if(this.partnerRemarks === ""){
      //       this.remarksErr= true
      //       this.remarksMsg = "Please enter remarks"
      //     }

      //     else{
      //       micromodal.close('show-branch-modal')
      //     }
      //     let param = {
      //       isActive: this.isBranchActive ? false : true,
      //       partnerId: this.$route.params.id,
      //       remarks: this.partnerRemarks,
      //       provider: "BRANCH"
      //     }
      //     restApi.post('employee/changePaymentProcessor', encodeWithParam(param)).then(() => {

      //   let param = {
      //       decoded: {
      //         partnerID: this.$route.params.id
      //     }
      //   }

      //       this.fetchPaymentStatus(param)

      //       this.paymentProcessor = "BRANCH"
      //     })
      //   }
      // },
      maskDetail(label, value) {
        return partnerMethods.maskDetail(label, value, this.isMasked)
      },
      handleIsMasked(section) {
        this.isMasked[section] = false
        setTimeout(() => {
          this.isMasked[section] = true
        }, "3000")
      },
      declineChange(payment) {
        const isONE = payment == "ONE"
        const isBRANCH = payment == "BRANCH"

        if (isONE) {
          this.paymentProcessor = "BRANCH"
          this.$refs.branch.checked = true; this.$refs.one.checked = false;
        } else if (isBRANCH) {
          this.paymentProcessor = "ONE"
          this.$refs.branch.checked = false; this.$refs.one.checked = true;
        }
      },
      async sendToDMS(clientLocationPartnerID) {
        try {
          this.dmsError = this.dmsSuccess = "";
          await restApi.post(`/walmart/onboarding`, encodeWithParam({ clientLocationPartnerID })).then(async (responseData) => {
            let data = responseData?.data?.data;
            if (data?.payee_id) {
              this.$props.partner.dms_id = data?.payee_id;
            } else {
              this.dmsSuccess = responseData?.data?.message || "Error sending to DMS";
            }
          });
        } catch (error) {
          const response = error?.response?.data;
          this.dmsError = "Error having special characters in email, city or firstname less that two characters" || response?.error || response?.description || "Error sending to DMS";
        }
      },
      async resendWallet() {
        this.isResent = true
        await restApi.post(`/partner/reCreateWallet`, {
          "param": window.btoa(JSON.stringify({ partnerID: this.$route.params.id }))
        })
          .then(({ data }) => {
            this.isResent = true;
            this.resentMessage = data.message;
            this.messageClass = data.success;
            setTimeout(() => {
              this.isResent = false;
              this.resentMessage = "";
              this.refetch();
            }, 2000)
          })
      },
      copyMagiclink() {
        const ver = document.getElementById("magiclink");
        navigator.clipboard.writeText(ver.value);
      },
      openEditPartner() {
        micromodal.show("editPartner");
        document.body.style.overflow = "hidden";
      },
      openUnregister(){
        micromodal.show('show-ach-modal')
      },

      openEditBusiness(){
        micromodal.show("editPartnerBusiness");
        document.body.style.overflow = "hidden";
      },

      openAddBusiness(){
        console.log("herer");
        micromodal.show("addPartnerBusiness");
        
        
      },

      downloadTaxPacket(year) {
        this.taxData = false
        const param = {
          partnerID: this.$route.params.id,
          year: year
        }
        restApi.post(`/tax/downloadTaxDocs`, encodeWithParam(param))
          .then(({ data }) => {
            if (data.length > 0) {
      restApi.post('employee/getUploadedFile',encodeWithParam({fileID: data[0]})).then((content)=>{
           let buffer = Buffer.from(content.data.bufferInHex, "hex");
            let buf64 = buffer.toString("base64");
            let bufferData = "data:application/pdf;base64,"+buf64
            this.saveZip(`${this.$route.params.id}_${year}.zip`, year, bufferData)
        })
            }
            else {
              this.taxData = true
            }
          })
      },

         saveZip(filename, year, data) {
        if (!data) return;
        const zip = new JSZip();
        const folder = zip.folder(`${this.$route.params.id}_${year}`);
          const blobPromise = fetch(data).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
          });
          const name = `${this.$route.params.id}.pdf`
          folder.file(name,blobPromise);

        zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, filename));

      },
      refetch() {
        this.$emit("refetch");
      },
      formatHistory(date) {
        return DateTime.fromISO(date).setZone("America/Los_Angeles").toFormat('hh:mm a, LL/dd/y')
      },
      showModals() {
        const [location, one] = [this.providerStatus.location, this.providerStatus.one];
        if (!location.is_one_integration_enabled) {
          this.providerErrorMessage = `Unable to switch to ONE since the location is \nnot enabled with instant payment`
          // this.providerErrorMessage = `Unable to switch to ONE since the location is not enabled with instant payment`
          micromodal.show("show-provider-error")
          this.$refs.branch.checked = true; this.$refs.one.checked = false;
        } else if (one?.partner_id && this.failStatuses.includes(one.one_status)) {
            this.providerErrorMessage = `Unfortunately we are unable to switch to \n ONE since your account is ${one.one_status.toLowerCase()}`
            micromodal.show("show-provider-error")
            this.$refs.branch.checked = true; this.$refs.one.checked = false;
        } else {
            micromodal.show("show-modal");
        }
      },
      branchModal() {
        const branch = this.providerStatus.branch;
        if (branch?.partnerID && this.failStatuses.includes(branch.status)) {
          this.providerErrorMessage = `Unfortunately we are unable to switch to \n Branch since your account is ${branch.status.toLowerCase()}`
          micromodal.show("show-provider-error")
          this.$refs.branch.checked = false; this.$refs.one.checked = true;
        } else {
            micromodal.show("show-branch")
        }
      },
      // checkWalletStatus(type) {
      //   return type == "ONE" ? this.providerStatus?.one?.is_active : ["UNCLAIMED", "ACTIVE"].includes(this.providerStatus?.branch?.status);
      // },
      closeToast() {
        this.displayMessage = false;
        this.displayMessageNew = false;
        location.reload();
      },
    },
  };
</script>

<style scoped>
  .text-scroll {
    width: 100%;
    height: 80px;
    overflow: auto;
    overflow-x: hidden;
  }

  .resent-to-branch-history {
    overflow: auto;
    width: 50%;
    height: 50px;
  }

  .resent-to-branch-history-postal {
    overflow: auto;
    width: 50%;
    height: 50px;
  }

  .resent-to-branch-history p:first-child,
  .resent-to-branch-history-postal p:first-child {
    padding-top: 0px;
  }

  a:hover {
    text-decoration: none;
  }

  .btn-padding {
    padding: 9px 8px 9px 8px;
  }

  main {
    padding-top: 0;
  }

  .margin-btm-32 {
    margin-bottom: 32px;
  }

  .inner-div {
    position: absolute;
    margin-top: 16px;
  }

  .error {
    color: var(--error-40);
  }

  .success {
    color: var(--success-40);
  }
  .pre-formatted {
    white-space: pre;
  }

  .profile-box-content {
    margin-top: 20px;
    position: absolute;
  }

  .btn-margin{
margin: 6px;
  }
.red{
    border-color: #E71742 !important;
    color: #E71742;
    /* border: 1px solid #E71742 !important; */
  }

  .dropbtn {
    color: white;
    font-size: 16px;
    border: none;
    background: var(--white);
    color: var(--link);
    border-color: var(--link);
    padding: 9px 8px 9px 8px;
    cursor: pointer;
    display: inline-block;
    -webkit-appearance: none;
    -moz-apperance: none;
    text-decoration: none;
    font-size: 16px;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    border: 1px solid var(--info-80);
    border-radius: 4px;
    line-height: 1;
    margin-right: 25px;

  }

  .mod-label {
    margin: 10px 0px 5px 0px;
    color: grey;
  }

  .mod-content {
    padding: 0px;
    font-size: 12px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-disabled {
    position: relative;
    display: inline-block;
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed !important;
  }


  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: var(--info-80);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 200px;
  }

  .text-wrap {
    display: block;
    width: 300px;
    word-wrap: break-word;
  }

  .dropdown-content a:hover {
    background-color: rgb(246, 241, 241);
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #ddd;
  }

  .dropdown:hover .dropdown-content-disabled {
    display: none;
  }

  .dropdown-content-disabled a {
    display: none;
  }

  .justify-content {
    display: flex;
    justify-content: space-between;
  }

  .justify-content>.inner-icon {
    float: right;
    position: relative;
    top: 10px;
    height: 5px;
    right: 15px;
  }
  .inner-icon1 {
    float: right;
    position: relative;
    bottom: 55px;
    height: 5px;
    left: 32px;
  }

  .extra-info {
    padding-left: 29px;
    font-size: 14px;
  }

  .active-cls {
    color: #2A8703;

  }

  .align-padding {
    margin-bottom: 10px;
  }

  .primary-blue {
    color: #0071DC
  }

  .yesBut {
    background-color: #E71742;

    margin-left: 23%;
  }

  .noBut {
    color: #2E2F32;
  }

  .toastpayment {
    position: absolute;
    top: -62px;
    left: 30%;
    background-color: #00CBB7;
    color: #FFFFFF;
    z-index: 5;
  }

  .boldname {
    font-weight: 700;
  }

  .pclass {
   font-family: Montserrat;
    font-weight: 500;
    color: #2E2F32;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 150%; */
    margin-top: 10px;
    padding-left: 0;
    margin-left: 14%;
}
  .p-warning {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
  }

  .vl {
  opacity: 0.1;
  border: 1px solid #000000;
  height: 250px;
  margin-left: 240px;
}

.activate{
  display: flex;
    flex-direction: column;
}
.radioButton{
  right: 93px;
  position: relative;
  bottom: -17px;
}

.disable-eye {
    opacity: 0.4;
    pointer-events: none;
  }
</style>
