<template>
  <fragment>
    <h1>Partner Notes</h1>
    <div>
      <div class="notes-user-main" v-if="oldNotes">
        <div class="notes-user-card">
          <div class="notes-user-message">
            <p>{{ oldNotes }}</p>
          </div>
        </div>
      </div>
      <div v-if="notesObj" class="notes-user-main">
        <div v-for="item in notesObj" :key="item.date" class="notes-user-card">
          <div class="user-heading">
            <p class="user-p">{{ item.user }}</p>
            <p class="notes-date">{{ item.date | formatDatePacific }}</p>
          </div>
          <div class="notes-user-message">
            <!-- <p>{{ item.message }}</p> -->
            <textarea class="notes-display" name="message" id="" cols="2" rows="2" v-model="item.message" disabled></textarea>
          </div>
        </div>
      </div>
      <div v-if="!notesObj && !oldNotes">No Notes</div>
    </div>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import { restApi } from "@/http/http.config";
import {encodeWithParam} from "@/util/Base64Encoding";


export default {
  name: "PartnerNotes",
  components: {
    Fragment,
  },
  data: function () {
    return {
      partner: {},
      notes: {},
      notesText: "",
      oldNotes: "",
    };
  },
  computed: {
    notesObj() {
      return this.notes ?.notes ? this.notes.notes : null;
    },
  },
  methods: {
    getPartnerNotes(obj){
        restApi.post('onboard/getNotes', encodeWithParam(obj))
        .then(data => {
          this.notes = data.data.result[0].notesList;
          this.oldNotes = data.data.result[0].notes;
        });
  }
    },
  
      mounted(){
        const obj = { partnerID: this.$route.params.id}
        this.getPartnerNotes(obj)
      }
};
</script>

<style scoped>

  .notes-display{

    padding: 1px; 
    border: none; 
    font-size: 10pt; 
    color:#000000; 
    background-color:#ffffff;

  }


</style>